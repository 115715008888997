/**
 * EntryList
 */

import React from 'react';
import CardEntry from 'Components/CardEntry';
import Title from 'Components/Title';
import s from './EntryList.module.scss';

const EntryList = ({ tag, title, entries }) => {

    const hasHeader = Boolean(tag || title);

    return(
        <section className={s['EntryList']}>
            <div className={s['EntryList__Container']}>
                {hasHeader && (
                    <Title title={title} tag={tag} />
                )}
                {entries && (
                    <div className={s['EntryList__Wrapper']}>
                        {entries.map((entry, index) => (
                            <div className={s['EntryList__Item']} key={index}>
                                <CardEntry {...entry} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default EntryList;
