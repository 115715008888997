/**
 * Title
 */

import React from 'react';
import classNames from 'classnames';
import s from './Title.module.scss';

// TODO: Check that modifiers work
const Title = ({
    title = '',
    tag = '',
    modifiers = [],
    children = '',
}) => {

    const classes = classNames(
        s['Title'],
        modifiers.map((x) => [s[`Title--${x}`]]),
        {[s['Title--HasChildren']]: !!children},
    );

    return (
        <div className={classes}>
            {tag && (
                <span className={s['Title__Tag']}>{tag}</span>
            )}
            {title && (
                <h2 className={s['Title__Title']}>{title}</h2>
            )}
            {children}
        </div>
    );
};

export default Title;
